$breakpoints: (
  'phone': 300px,
  'phone-wide': 480px,
  'phablet': 560px,
  'tablet-small': 640px,
  'tablet': 768px,
  'midpoint': 980px,
  'tablet-wide': 1024px,
  'desktop': 1200px,
  'large': 1500px
);

$Driod: 'Droid Sans', sans-serif;

$green: #0dc5c1;
$black: #333;
$gray: #ccc;
$arrow: '\e902';
$grid: '\e900';
$list: '\e9ba';
$search: '\e901';
