@import './scss/core';

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

@font-face {
  font-family: 'icomoon';
  src:
    url('./fonts/icomoon.ttf?cvpmwa') format('truetype'),
    url('./fonts/icomoon.woff?cvpmwa') format('woff'),
    url('./fonts/icomoon.svg?cvpmwa#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: $Driod;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $gray;
}

