@function strip-unit($number) {
  @if type-of($number )== 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }
  @return $number;
}
@function rem($pixels, $context: 16) {
   @return #{strip-unit($pixels)/$context}rem;
}

@mixin pseudo($display: block, $pos: absolute, $content: '', $icon: 'false') {
  content: $content;
  display: $display;
  position: $pos;

  @if $icon == 'true' {
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  }
}

@mixin mq($width, $type: min) {
  @if map_has_key($breakpoints, $width) {
    $width: map_get($breakpoints, $width);
    @if $type == max {
      $width: $width - 1px;
    }
    @media only screen and (#{$type}-width: $width) {
      @content;
    }
  }
}

@mixin mq-min-max($min, $max) {
  @if map_has_key($breakpoints, $min) {
    $min: map_get($breakpoints, $min);
    $max: map_get($breakpoints, $max);
    @media only screen and (max-width: $max) and (min-width: $min) {
      @content;
    }
  }
}

@mixin input-placeholder {
  &.placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }

  &::-webkit-input-placeholder {
    @content;
  }
}
