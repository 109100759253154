@import '../../../scss/core';

.NewsHolder {
  min-height: calc(100vh - 128px);
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  max-width: 1800px;
  margin: 10px auto;

  @include mq('tablet-small') {
    margin: 30px auto;
    align-items: inherit;
    align-content: baseline;
  }
}

.ShowNewsEnter {
  opacity: 0;
  transform: translateY(2%);
}

.ShowNewsEnterActive {
  opacity: 1;
  transform: translateY(0);
  transition: all 0.3s ease-in;
}

.CloseNewsEnter {
  opacity: 1;
  transform: translateY(0);
}

.CloseNewsEnterActive {
  opacity: 0;
  transform: translateY(2%);
  transition: all 0.3s ease-in;
}
